import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Background from "../components/Background.js"
import Content from "../components/page/candidate/Content.js"
import { isBlank } from "../utils/isBlank"

export default function Home({ data }) {
  return (
    <div>
      <Background>
        <Page data={data} />
      </Background>
    </div>
  )
}

function Page({ data }) {
  const [isWindowBlank, setWindowBlank] = useState(false)
  const candidate = data.allDatoCmsGeneralelectioncandidate.nodes[0]
  var defaultMargin = " -my-36 md:-my-56 "
  if (isWindowBlank) defaultMargin = ""

  useEffect(() => {
    setWindowBlank(isBlank(window))
  })

  return (
    <div className={" md:w-4/5  xl:max-w-7xl " + defaultMargin}>
      {!isWindowBlank && (
        <Logo logo={data.datoCmsWebsiteFile.logo.gatsbyImageData} />
      )}
      <Content candidate={candidate} />
    </div>
  )
}

function Logo({ logo }) {
  return <GatsbyImage className="w-36 " alt="Liike Nyt logo" image={logo} />
}

export const query = graphql`
  query ($slug: String!) {
    datoCmsWebsiteFile {
      logo {
        gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
      }
    }
    allDatoCmsGeneralelectioncandidate(filter: { id: { eq: $slug } }) {
      nodes {
        name
        age
        electionarea
        blog
        koulutus
        ammattiTitteli
        campaignDescription
        email
        facebook
        instagram
        municipality
        personalDescription
        surname
        picture {
          gatsbyImageData
        }
      }
    }
  }
`
